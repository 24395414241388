export const newsBoxes = [
  {
    box1: [3, `this.state.filtered1`, "Najnovije vesti", "boxWrapper sideBox"],
  },
];

export const newsShedule = [
  { home: [49, 47, 30] }, //actual news ids
  { home_topNews: [1, 2] },
];
export const newsShedule2 = [
  [[1, 4], "home__rightBox", "boxWrapper box1"],
  [[4, 7], "home__leftBox", "boxWrapper box1"],
  // [[0, 3], "box3__left", "boxWrapper box3"],
  [[8, 10], "home__box__4-layout", "boxWrapper mainBox bigBox"],
  [[10, 14], "home__box__4-layout", "boxWrapper box4"],
];
export const options = [
  //0
  {
    title: "Početna",
    route: "/",
    icon: "/img/icons/home.png",
    social: {
      title: "Gardenia.rs - vaš vodič za savršenu baštu i dvorište",
      lead: "Uživajte u svojoj bašti i dvorištu uz Gardenia.rs! Pronađite savete za uzgajanje biljaka i cveća, održavanje dvorišta i izbor pravih alata za vaše potrebe. Saznajte kako da pretvorite vaše dvorište u rajski kutak uz naše korisne članke i savete.",
      pics: "/img/test3.jpg",
    },
  },
  //1
  {
    title: "Novosti",
    route: "/novosti/",
    icon: "/img/icons/other.png",
    social: {
      title: "Novosti",
      lead: "Gardenia magazine",
      pics: "/img/test3.jpg",
    },
  },
  //2
  {
    title: "Bašta",
    route: "/basta/",
    icon: "/img/icons/manifestacije.png",
    social: {
      title: "Bašta",
      lead: "Bašta",
      pics: "/img/test3.jpg",
    },
  },
  //3
  {
    title: "Cveće",
    route: "/cvece/",
    icon: "/img/icons/other.png",
    social: {
      title: "Cveće",
      lead: "Cveće",
      pics: "/img/test3.jpg",
    },
  },
  //4
  {
    title: "Uređenje dvorišta",
    route: "/uredjenje-dvorista/",
    icon: "/img/icons/other.png",
    social: {
      title: "Uređenje dvorišta",
      lead: "Uređenje dvorišta",
      pics: "/img/test3.jpg",
    },
  },
  //5
  {
    title: "Ostalo",
    route: "/ostalo/",
    icon: "/img/icons/other.png",
    social: {
      title: "Ostalo",
      lead: "Ostale vesti",
      pics: "/img/test3.jpg",
    },
  },
];

export const footer = [
  ["O nama", "/o-nama"],
  ["Marketing", "/marketing"],
  ["Pravila korišćenja", "/pravila-koriscenja"],
  ["Politika privatnosti", "/politika-privatnosti"],
];

export const infoText = [
  ["O nama", "Agrweb.rs je poljoprivredni portal koji pruža sveobuhvatne informacije o poljoprivrednoj proizvodnji u Srbiji. Na našem portalu možete pronaći najnovije vesti, savete i tehnologije iz oblasti poljoprivrede, kao i informacije o proizvodima i uslugama koje nude domaći poljoprivredni proizvođači.<br /> Cilj nam je da pomognemo poljoprivrednicima da unaprede svoju proizvodnju i da budu uspešni u svojim poslovima. Pratite nas i informišite se o tome kako da unapredite svoju poljoprivrednu proizvodnju. <br /> Pored toga, na portalu nudimo i mogućnost oglašavanja za sve one koji žele da promovišu svoje proizvode i usluge."],
  ["Marketing", ""],
  ["Pravila korišćenja", ""],
  ["Politika privatnosti", ""],
];

export const asideBoxes = [
  [0, 3, "Najnovije vesti", "boxWrapper sideBox"],
  [3, 3, "Najčitanije vesti", "boxWrapper sideBox"],
  [1, 2, "Najnovije vesti - ", "boxWrapper mainBox new", "home__rightBox", true, "home__box__13"],
  [2, 3, "Preporučujemo", "boxWrapper box2", "home__rightBox", false, "home__box__11"],
];
