import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import Gardenia from "./Gardenia.js";
// import allNews from "./all__news.json";
// import { createStore } from "redux";
// import allReducers from "./Redux/Index";
// import { Provider } from "react-redux";
// import HeadHelmet from "./components/HeadHelmet/HeadHelmet";

// const store = createStore(
//   allReducers,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

ReactDOM.render(
    <Gardenia />,
  document.getElementById("root")
);
// ReactDOM.render(
//   <Provider store={store}>
//     <HeadHelmet allNews={allNews} />
//     <Gardenia />
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
