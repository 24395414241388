import React from "react";
import { Tweet } from "react-twitter-widgets";
import "./BodyText.scss";

const setSubTitle = (item) => {
  if (item.subtitle) {
    return <h2 className="mainContent-subtitle">{item.subtitle}</h2>;
  } else if (item.subtitle3) {
    return <h3 className="mainContent-subtitle3">{item.subtitle3}</h3>;
  }
};
const setTweet = (item) => {
  if (item > 0) {
    return <Tweet tweetId={item} />;
  }
};
const setBlockQuote = (item) => {
  if (item.boxInText) {
    return (
      <blockquote>
        <h3>{item["boxInText"][0]}</h3>
        <p dangerouslySetInnerHTML={{ __html: item["boxInText"][1] }}></p>
      </blockquote>
    );
  }
};
export default function BodyText(props) {
  return (
    <div className={`bodyText ${props.pr}`}>
      {/* <React.Fragment className="bodyText"> */}
      {props.bodyText.map((item, index) => {
        if (index === 0) {
          return (
            <div key={index}>
              {setSubTitle(item)}
              <p dangerouslySetInnerHTML={{ __html: item["text"] }}></p>
              {setTweet(item.tweetId)}
            </div>
          );
        } else {
          return (
            <div key={index} style={{ marginTop: "0.2rem" }}>
              {setSubTitle(item)}
              <p dangerouslySetInnerHTML={{ __html: item["text"] }}></p>
              {setBlockQuote(item)}
              {setTweet(item.tweetId)}
            </div>
          );
        }
      })}
    </div>
    // </React.Fragment>
  );
}
