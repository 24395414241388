import React, { Component } from "react";
import "./Home.scss";
import Backdrop from "../../components/Bars/Backdrop/Backdrop";
import { newsShedule, newsShedule2, options } from "../../shared/shared";
// import AdvModule from "../../components/AdvModule/AdvModule";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
// import SocialLinks from "../../components/HeadHelmet/HeadHelmet";
// import Widget from "../../UI/Widget/Widget";
import Gallery from "../../components/Gallery/Gallery";
import { useIdContext } from "../../shared/IdProvider";
export default function Home() {
  const { publishedNews, actualBoxNews, topNews } = useIdContext();
  const displayClassName = (classes) => {
    if (window.innerWidth > 500) {
      return "boxWrapper box2";
    } else {
      return "boxWrapper mainBox bigBox";
    }
  };
  const setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  const boxes = [
    setNewsBoxes([1, 3], publishedNews, "home__rightBox", "boxWrapper sideBox"),
    setNewsBoxes(
      [4, 7],
      publishedNews,
      "home__leftBox",
      displayClassName()
      // "boxWrapper box2"
    ),
    setNewsBoxes([0, 3], actualBoxNews, "box3__left", "boxWrapper box3"),
    setNewsBoxes([8, 10], publishedNews, "home__box__4-layout", "boxWrapper mainBox bigBox"),
    setNewsBoxes([10, 14], publishedNews, "home__box__4-layout", "boxWrapper box4"),
  ];
  return (
    <div className="home__1">
      {/* <SocialLinks title={""} post={options[0].social} /> */}
      {/* <AdvModule classes={"adClass_Footer"} size={"s/f"} /> */}
      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
      <section className="homeTopNews">{topNews && topNews.length > 1 ? <ArticalBox n={publishedNews[0]} classes="boxWrapper topNews" /> : null}</section>
      <section className="home__box__1">
        <div className="home__box">
          <ArticalBox n={publishedNews[1]} classes="boxWrapper sideBox" />
        </div>
      </section>
      <section className="home__box__1">
        <div className="home__box">
          <ArticalBox n={publishedNews[2]} classes="boxWrapper sideBox right" />
        </div>
      </section>
      <section className="home__box__2">
        <Gallery />
      </section>
      <section className="home__box__1">
        <div className="home__box">
          <ArticalBox n={publishedNews[3]} classes="boxWrapper sideBox" />
        </div>
      </section>
      {/* <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={publishedNews[0]} classes="boxWrapper sideBox" />
          </div>
        </section> */}
      <section className="homeTopNews">{topNews && topNews.length > 1 ? <ArticalBox n={topNews[1]} classes="boxWrapper topNews" /> : null}</section>

      <section className="home__box__1">
        <div className="home__box">
          <ArticalBox n={publishedNews[4]} classes="boxWrapper sideBox" />
        </div>
      </section>
      {/* <section className="home__box__3">
          <Widget />
        </section> */}
      {/* <h2>{"TEST"}</h2> */}
      {/* <AdvModule classes={"adClass small"} size={"s"} />
        <section className="home__box__2">
          {boxes[1]}
          <div className="home__rightBox">
            <AdvModule classes={"adClass large"} size={"xl"} />
            <AdvModule classes={"adClass small"} size={"s"} />
          </div>
        </section> */}
      {/* <section className="topNews">{this.state.topNews && <ArticalBox n={this.state.topNews[0]} classes="boxWrapper topNews" />}</section> */}
      {/* <section className="home__box__3">
          <div className="home_3_title">
            <span>Aktuelno</span>
          </div>
          <div className="box3">{boxes[2]}</div>
        </section> */}
      {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} />
        <section className="home__box__4">
          <span>Pročitajte još...</span>
          {boxes[3]}
          {boxes[4]}
        </section>
        <AdvModule classes="adClass__XXL" size={"xxl"} /> */}
      {/* <section className="topNews">
          {this.state.topNews && this.state.topNews.length > 1 ? (
            <ArticalBox2
              n={this.state.topNews[1]}
              classes="boxWrapper topNews"
            />
          ) : null}
        </section> */}
      {/* <Backdrop clicked={this.removeCommentBox} show={this.state.showPopup} /> */}
    </div>
  );
}
