import React, { useEffect, useState } from "react";
import "./News2.scss";
import ArticalBox2 from "../../components/ArticalBox/ArticalBox.js";
import Pagination from "../../UI/Pagination/Pagination.js";
// import AdManagerSlot from "../../components/AdvModule/AdManagerSlot.js";
import { urlTitle2 } from "../../shared/utillity.js";
import { useGlobalLocation } from "../../shared/LocationContext.js";
import { useIdContext } from "../../shared/IdProvider.js";

export default function News2(props) {
  let { location, pathPart } = useGlobalLocation();
  const { publishedNews, id, slug } = useIdContext();
  const categoryNews = setCategoryNews();

  //state for pagination
  const numberOfPages = Math.max(0, Math.ceil((categoryNews.length - 4) / 3 - 1));
  const [pageNum, setPageNum] = useState(0);
  const [elemNum, setElemNum] = useState([4, 7]);
  useEffect(() => {
    if (pageNum === 0) {
      window.scrollTo(0, 0);
    }
  }, [pathPart]);
  function setCategoryNews() {
    if (pathPart[1] === "tag") {
      let tagNews = publishedNews.filter((item) => {
        let newTags = item.tags.map((item) => {
          return urlTitle2(item);
        });
        return newTags.includes(pathPart[2]);
      });
      return tagNews;
    } else {
      let catNews = publishedNews.filter((item) => {
        return urlTitle2(item.category) === slug || location.pathname === "/najnovije-vesti/";
      });
      return catNews;
    }
  }
  const setPaginationPage = () => {
    setElemNum([4, elemNum[1] + 3]);
    setPageNum(pageNum + 1);
  };

  const setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox2 key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };

  //change class with dif width
  const setClass = () => {
    if (window.innerWidth < 500) {
      return "boxWrapper box2 Spec";
    } else {
      return "boxWrapper mainBox";
    }
  };
  const id2 = () => {
    if (pathPart[2] === "subvencije") {
      return "Spec";
    }
  };
  const setTagTitle = () => {
    let tagTitle = categoryNews[0].tags.filter((item) => {
      return urlTitle2(item) === pathPart[2];
    });
    return tagTitle;
  };

  return (
    <div className={`news2 ${id2()}`}>
      <div className="news2-wrapper">
        <div className="banner-wrapper">{/* <AdManagerSlot adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1710624971857-0"} /> */}</div>
        {/* {pathPart[1] === "tag" ? <h1>{setTagTitle()}</h1> : null} */}
        <section className="topNews">
          <ArticalBox2 n={categoryNews[0]} classes={`boxWrapper topNews ${id}`} />
        </section>
        <section className="home__box__11">
          {/* {setNewsBoxes([1, 4], categoryNews, "home__rightBox", `boxWrapper box2 ${id2()}`)} */}
          <div className="home__adBox">
            <div className="banner-wrapper">{/* <AdManagerSlot adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1711058573014-0"} /> */}</div>
          </div>
        </section>
        {/* <section className="home__box__2">{setNewsBoxes([elemNum[0], elemNum[1]], categoryNews, "home__box__2-layout", setClass())}</section> */}
        <Pagination numberOfPages={numberOfPages} pageNum={pageNum} clicked={setPaginationPage} />
        <div className="banner-wrapper">{/* <AdManagerSlot adUnitPath={location.pathname} slotNumber={"div-gpt-ad-1711282241522-0"} /> */}</div>
      </div>
    </div>
  );
}
